import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "react-router";

import AuthContext from "contexts/AuthContext";

type ProtectedRouteProps = {
  children: React.ReactNode;
} & RouteProps;

function ProtectedRoute(props: ProtectedRouteProps) {
  const { authenticated } = useContext(AuthContext);

  return authenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: "/" }} />
  );
}

export default ProtectedRoute;
