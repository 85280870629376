import { SnackbarOptions, UserProfile } from "types/types";

export const defaultSnackbarOptions: SnackbarOptions = {
  message: "",
  open: false,
  type: "success"
};

export const defaultAppsPlaceholders = [
  { id: "a", name: "a" },
  { id: "b", name: "b" },
  { id: "c", name: "c" }
];
