import { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, useLocation } from "react-router-dom";

import ProtectedRoute from "components/protected-route/ProtectedRoute";
import SnackbarInfo from "components/snackbar-info/SnackbarInfo";
import ReAuthModal from "components/modals/re-auth-modal/ReAuthModal";

const Applications = lazy(() => import("pages/applications/Applications"));
const Access = lazy(() => import("pages/access/Access"));

function ProtectedRoutes() {
  const { pathname } = useLocation();
  const currentPath = useRef("");

  useEffect(() => {
    currentPath.current = pathname;
  }, [pathname]);

  return (
    <Suspense fallback={null}>
      <Switch>
        <ProtectedRoute path="/applications">
          <Applications />
        </ProtectedRoute>
        <ProtectedRoute path="/access">
          <Access />
        </ProtectedRoute>
      </Switch>
      <ReAuthModal />
      <SnackbarInfo />
    </Suspense>
  );
}

export default ProtectedRoutes;
