import { useTranslation } from "react-i18next";
import { openURL, AURA_CONTACT_URL } from "utils/utils";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";

import idxLogo from "assets/images/idxWhiteTitleAlt_Medium.png";

import classes from "./LoginHeader.module.scss";

type LoginHeaderProps = {
  forgotPassword?: boolean;
  resetPassword?: boolean;
  userNotFound?: boolean;
  loginMethodNotAllowed?: boolean;
};

function LoginHeader({
  forgotPassword = false,
  resetPassword = false,
  userNotFound = false,
  loginMethodNotAllowed = false
}: LoginHeaderProps) {
  const { t } = useTranslation("common");

  let content = (
    <div className={classes.content}>
      <div>{t("enter-your-details")}</div>
      <div>
        {t("cannot-sign-in")}
        <span
          className={classes["contact-link"]}
          onClick={() => openURL(AURA_CONTACT_URL, false)}
        >
          {t("contact-us")}
        </span>
      </div>
    </div>
  );

  if (forgotPassword) {
    content = (
      <div className={classes.content}>
        {t("reset-password-form.enter-login")}
      </div>
    );
  }

  if (resetPassword) {
    content = <div>{t("reset-password-form.enter-password")}</div>;
  }

  if (userNotFound) {
    content = (
      <div className={clsx(classes.content, classes["user-not-found"])}>
        <ErrorOutlineIcon className={classes["user-not-found-icon"]} />
        <span>{t("user-not-found")}</span>
      </div>
    );
  }

  if (loginMethodNotAllowed) {
    content = (
      <div className={clsx(classes.content, classes["user-not-found"])}>
        <ErrorOutlineIcon className={classes["user-not-found-icon"]} />
        <span>{t("login-method-not-allowed")}</span>
      </div>
    );
  }

  return (
    <div className={classes["form-header"]}>
      <img src={idxLogo} alt="IDX Logo" />
      <div className={classes["form-header-content"]}>{content}</div>
    </div>
  );
}

export default LoginHeader;
