import { forwardRef } from "react";
import clsx from "clsx";

import classes from "./FormTextarea.module.scss";

type FormTextareaProps = {
  name?: string;
  error?: string;
  formTextareaClass?: string;
};

const FormTextarea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  ({ name, error, formTextareaClass = "" }, ref) => {
    return (
      <div
        className={clsx(classes.container, {
          [formTextareaClass]: formTextareaClass
        })}
      >
        <textarea
          ref={ref}
          name={name}
          className={clsx(classes.textarea, {
            [classes["error-border"]]: error
          })}
          autoComplete="none"
        />
        {error && <span className={classes["error-mssg"]}>{error}</span>}
      </div>
    );
  }
);

export default FormTextarea;
