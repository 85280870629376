import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { BiLogIn } from "react-icons/bi";
import TranslateIcon from "@material-ui/icons/Translate";
import Zoom from "@material-ui/core/Zoom";

import LanguageSelect from "./components/language-select/LanguageSelect";
import AuraLogo from "components/logos/aura-logo/AuraLogo";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import LanguageModal from "components/modals/language-modal/LanguageModal";

import classes from "./Header.module.scss";

function Header() {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { t } = useTranslation(["common", "edit-profile"]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);

  const isLandingPage = pathname === "/";

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const redirectToLoginPage = () => {
    push("/login");
  };

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
    closeMenu();
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  return (
    <>
      <div className={classes["header"]}>
        <div className={classes["header-components"]}>
          <AuraLogo />
          <div className={classes["right-container"]}>
            <LanguageSelect />
            {isLandingPage && (
              <PrimaryButton
                primaryButtonClass={classes["sign-in-button"]}
                onClick={redirectToLoginPage}
              >
                {t("sign-in")}
              </PrimaryButton>
            )}
          </div>

          <div className={classes["menu-icon-container"]} onClick={openMenu}>
            <MenuIcon className={classes["menu-icon"]} />
          </div>

          <Menu
            id="side-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            TransitionComponent={Zoom}
          >
            {isLandingPage && (
              <MenuItem
                onClick={redirectToLoginPage}
                classes={{ root: classes["menu-item"] }}
              >
                <BiLogIn className={classes["sign-in-icon"]} />
                <span className={classes["menu-item-label"]}>
                  {t("sign-in")}
                </span>
              </MenuItem>
            )}

            <MenuItem
              onClick={openLanguageModal}
              classes={{ root: classes["menu-item"] }}
            >
              <TranslateIcon />
              <span className={classes["menu-item-label"]}>
                {t("edit-profile:menu.language")}
              </span>
            </MenuItem>
          </Menu>
        </div>
      </div>

      <LanguageModal open={languageModalOpen} onClose={closeLanguageModal} />
    </>
  );
}

export default Header;
