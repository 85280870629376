import { openURL, PRIVACY_POLICY_URL, AURA_URL } from "utils/utils";
import { useTranslation } from "react-i18next";

import IdxLogo from "components/logos/idx-logo/IDXLogo";

import classes from "./Footer.module.scss";

function Footer() {
  const { t } = useTranslation("common", { useSuspense: false });

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <IdxLogo />
        <div className={classes["aura-details"]}>
          <div
            className={classes.policy}
            onClick={() => openURL(PRIVACY_POLICY_URL, false)}
          >
            {t("privacy-policy")}
          </div>
          <div
            className={classes.copyright}
            onClick={() => openURL(AURA_URL, false)}
          >
            <span className={classes["copyright-sign"]}>&#xa9;</span>
            &nbsp;Copyright Aura&nbsp;{new Date().getFullYear()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
