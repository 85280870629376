import Modal from "components/ui/modal/Modal";
import LanguageRadioSelector from "./components/language-selector/LanguageSelector";
import { useTranslation } from "react-i18next";

type LanguageModalType = {
  open: boolean;
  onClose: () => void;
};

function LanguageModal({ open, onClose }: LanguageModalType) {
  const { t } = useTranslation("edit-profile", { useSuspense: false });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t("language-modal.language-selection")}
      </Modal.Header>
      <Modal.Content>
        <LanguageRadioSelector />
      </Modal.Content>
    </Modal>
  );
}

export default LanguageModal;
