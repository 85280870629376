import React from "react";
import { InputLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import classes from "./FormLabel.module.scss";

type FormLabelProps = {
  children: React.ReactNode;
  formLabelClass?: string;
  optional?: boolean;
};

function FormLabel({
  children,
  formLabelClass = "",
  optional = false
}: FormLabelProps) {
  const { t } = useTranslation("common", { useSuspense: true });

  return (
    <InputLabel
      className={clsx(classes["label"], {
        [formLabelClass]: formLabelClass
      })}
    >
      <div>{children}</div>
      {optional && <div className={classes["optional"]}>{t("optional")}</div>}
    </InputLabel>
  );
}

export default FormLabel;
