import { useContext } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import NotificationContext from "../../contexts/NotificationContext";

import classes from "./SnackbarInfo.module.scss";

function SnackbarInfo() {
  const { snackbarOptions, handleCloseSnackbar } =
    useContext(NotificationContext);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={snackbarOptions.open}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      classes={{ root: classes["snack-bar"] }}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={snackbarOptions.type}
        variant="filled"
      >
        <span className={classes["message"]}>{snackbarOptions.message}</span>
      </Alert>
    </Snackbar>
  );
}

export default SnackbarInfo;
