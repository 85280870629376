import React from "react";
import clsx from "clsx";

import classes from "./FormField.module.scss";

type FormFieldProps = {
  children: React.ReactNode;
  horizontal?: boolean;
  formFieldClass?: string;
};

function FormField({
  children,
  horizontal = false,
  formFieldClass = ""
}: FormFieldProps) {
  return (
    <div
      className={clsx(classes["field"], {
        [classes["field-horizontal"]]: horizontal,
        [formFieldClass]: formFieldClass
      })}
    >
      {children}
    </div>
  );
}

export default FormField;
