import { instance } from "axios-instance/axios-instance";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { UserProfile } from "types/types";

type AuthContextType = {
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  userDetails: UserProfile;
  setUserDetails: (userDetails: UserProfile) => void;
  shouldReAuth: boolean;
  setShouldReAuth: (shouldReAuth: boolean) => void;
};

const userDetailsInitial = {
  id: "",
  displayName: "",
  email: "",
  firstName: "",
  lastName: "",
  permissions: [],
  phone: "",
  role: ""
};

const AuthContext = createContext<AuthContextType>({
  authenticated: false,
  setAuthenticated: authenticated => {},
  userDetails: userDetailsInitial,
  setUserDetails: userDetails => {},
  shouldReAuth: false,
  setShouldReAuth: shouldReAuth => {}
});

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [shouldReAuth, setShouldReAuth] = useState(false);
  const [userDetails, setUserDetails] =
    useState<UserProfile>(userDetailsInitial);
  const history = useHistory();

  useEffect(() => {
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          error.response.status === 401 &&
          !error.response.config.url.includes("/auth")
        ) {
          setShouldReAuth(true);
        }
        return Promise.reject(error);
      }
    );
  }, [history.location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        userDetails,
        setUserDetails,
        shouldReAuth,
        setShouldReAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
