import { useTranslation } from "react-i18next";

import idxLogo from "assets/images/idxWhite.png";
import Modal from "components/ui/modal/Modal";

import classes from "./AboutModal.module.scss";

type AboutModalProps = {
  open: boolean;
  onClose: () => void;
};

function AboutModal({ open, onClose }: AboutModalProps) {
  const { t } = useTranslation("common", { useSuspense: false });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header onClose={onClose} noPadding />
      <Modal.Content>
        <div className={classes["about"]}>
          <div className={classes["about-logo-container"]}>
            <img
              src={idxLogo}
              className={classes["about-logo"]}
              alt="IDT logo"
            />
          </div>

          <div className={classes["about-info"]}>
            <div>
              Intelligent Digital&nbsp;
              <div className={classes["television"]}>Xchange</div>
            </div>
            <div className={classes["version"]}>{t("version")}&nbsp;1.0.0</div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default AboutModal;
