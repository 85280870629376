import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import LoginHeader from "../login-header/LoginHeader";

import classes from "../login/Login.module.scss";

function LoginMethodNotAllowed() {
  const { t } = useTranslation("common");

  return (
    <div className={classes.container}>
      <div className={clsx(classes["login-panel"], classes["user-not-found"])}>
        <LoginHeader loginMethodNotAllowed />

        <div className={classes["user-not-found-actions"]}>
          <Link to="/login">
            <Button variant="contained" classes={{ root: classes["button"] }}>
              <div className={classes["button-label"]}>
                <ArrowBackIcon className={classes["arrow-icon"]} />
                <span>{t("back-to-sign-in-form")}</span>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginMethodNotAllowed;
