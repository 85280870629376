import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Toolbar } from "@material-ui/core";

import LoadingContext from "contexts/LoadingContext";

import AuraLogo from "components/logos/aura-logo/AuraLogo";
import User from "components/layout/components/user/User";
import LinearLoader from "components/loaders/linear-loader/LinearLoader";

import classes from "./TopBar.module.scss";

function TopBar() {
  const { push } = useHistory();
  const { loading } = useContext(LoadingContext);

  const handleApplicationsClick = () => {
    push("/applications");
  };

  return (
    <AppBar className={classes["app-bar"]}>
      <Toolbar classes={{ root: classes["tool-bar"] }}>
        <div className={classes["logo-container"]}>
          <AuraLogo handleApplicationsClick={handleApplicationsClick} />
        </div>
        <User />
      </Toolbar>
      <LinearLoader loading={loading} />
    </AppBar>
  );
}

export default TopBar;
