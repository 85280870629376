import { ReactNode, SyntheticEvent, useRef } from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";

import classes from "./PrimaryButton.module.scss";

type PrimaryButtonProps = {
  children: ReactNode;
  primaryButtonClass?: string;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
};

function PrimaryButton({
  children,
  primaryButtonClass = "",
  disabled = false,
  onClick
}: PrimaryButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (buttonRef.current) {
    buttonRef.current.blur();
  }

  return (
    <Button
      className={clsx(classes["btn"], {
        [primaryButtonClass]: primaryButtonClass
      })}
      disabled={disabled}
      onClick={onClick}
      ref={buttonRef}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
