import { createContext, ReactNode, useState } from "react";
import { SnackbarOptions } from "types/types";
import { defaultSnackbarOptions } from "data/data";

type NotificationContextType = {
  snackbarOptions: SnackbarOptions;
  setSnackbarOptions: (snackbarOption: SnackbarOptions) => void;
  handleCloseSnackbar: () => void;
};

const NotificationContext = createContext<NotificationContextType>({
  snackbarOptions: defaultSnackbarOptions,
  setSnackbarOptions: (snackbarOption: SnackbarOptions) => {},
  handleCloseSnackbar: () => {}
});

export function NotificationContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions>(
    defaultSnackbarOptions
  );

  const handleCloseSnackbar = () => {
    setSnackbarOptions(prev => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider
      value={{ snackbarOptions, setSnackbarOptions, handleCloseSnackbar }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
