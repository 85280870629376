import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { availableCodes, setLanguageInCookie } from "utils/utils";

const lngInStorage = localStorage.getItem("i18nextLng");
let initialLng = lngInStorage ? lngInStorage : window.navigator.language;

if (!availableCodes.includes(initialLng)) {
  initialLng = "en-GB";
}

setLanguageInCookie(initialLng);

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: "en-GB",
    lng: initialLng,
    debug: true,
    react: {
      useSuspense: true,
      wait: true
    }
  });

export default i18n;
