import { ReactNode, Suspense } from "react";

import Header from "components/header/Header";

import classes from "./HomePageWrapper.module.scss";

const Fallback = () => <div className={classes["fallback"]}></div>;

function HomePageWrapper({ children }: { children: ReactNode }) {
  return (
    <div className={classes["home"]}>
      <Suspense fallback={<Fallback />}>
        <Header />
        <div>{children}</div>
      </Suspense>
    </div>
  );
}

export default HomePageWrapper;
