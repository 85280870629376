import { ReactNode } from "react";
import { DialogContent } from "@material-ui/core";

import classes from "../../Modal.module.scss";

function ModalContent({ children }: { children: ReactNode }) {
  return (
    <DialogContent className={classes["modal-content"]}>
      {children}
    </DialogContent>
  );
}

export default ModalContent;
