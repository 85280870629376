import PrimaryButton from "../../ui/buttons/primary-button/PrimaryButton";
import CircleLoader from "components/ui/loaders/circle-loader/CircleLoader";

import classes from "./ApplyButton.module.scss";

type ApplyButtonTypes = {
  label?: string;
  disabled?: boolean;
  onClick: () => void;
  loading?: boolean;
  smallFont?: boolean;
};

function ApplyButton({
  label = "Apply",
  disabled,
  onClick,
  loading,
  smallFont = false
}: ApplyButtonTypes) {
  return (
    <div className={classes["apply-button"]}>
      <PrimaryButton disabled={disabled || loading} onClick={onClick}>
        {!loading ? (
          <span className={smallFont ? classes["small-font"] : ""}>
            {label}
          </span>
        ) : (
          <CircleLoader />
        )}
      </PrimaryButton>
    </div>
  );
}

export default ApplyButton;
