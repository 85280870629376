import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { AuthContextProvider } from "contexts/AuthContext";
import { NotificationContextProvider } from "./contexts/NotificationContext";
import { LoadingContextProvider } from "contexts/LoadingContext";

import RedirectRoute from "routes/redirect-route/RedirectRoute";
import ProtectedRoutes from "routes/protected-routes/ProtectedRoutes";

import Layout from "components/layout/Layout";
import {
  WrappedLoginForm,
  WrappedForgotPasswordForm,
  WrappedResetPasswordForm,
  WrappedUserNotFoundPage,
  WrappedLoginMethodNotAllowedPage
} from "pages/home/Home";

import LandingPage from "pages/landing/LandingPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <StylesProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <NotificationContextProvider>
          <Router>
            <AuthContextProvider>
              <Switch>
                <Route path="/" exact component={LandingPage} />
                <Route path="/login" component={WrappedLoginForm} />
                <Route
                  path="/reset-password"
                  component={WrappedResetPasswordForm}
                />
                <Route
                  path="/forgot-password"
                  component={WrappedForgotPasswordForm}
                />
                <Route
                  path="/user-not-found"
                  component={WrappedUserNotFoundPage}
                />
                <Route
                  path="/login-method-not-allowed"
                  component={WrappedLoginMethodNotAllowedPage}
                />
                <RedirectRoute />
              </Switch>

              <LoadingContextProvider>
                <Layout>
                  <ProtectedRoutes />
                </Layout>
              </LoadingContextProvider>
            </AuthContextProvider>
          </Router>
        </NotificationContextProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </StylesProvider>
  );
}

export default App;
