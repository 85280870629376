import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";

import classes from "./CircleLoader.module.scss";

function CircleLoader({ customClass = "" }: { customClass?: string }) {
  return (
    <div
      className={clsx(classes["progress-container"], {
        [customClass]: customClass
      })}
    >
      <CircularProgress className={classes["progress"]} />
    </div>
  );
}

export default CircleLoader;
