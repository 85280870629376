import { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

type FlagProps = {
  code: string;
  width?: string;
  height?: string;
};

const FlagContainer = ({
  code,
  width = "25px",
  height = "15px"
}: FlagProps) => {
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    if (code === "en-GB") {
      setCountryCode("gb");
    } else {
      setCountryCode(code);
    }
  }, [code]);

  return (
    <div>
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        style={{
          width,
          height
        }}
      />
    </div>
  );
};

export default FlagContainer;
