import { LinearProgress } from "@material-ui/core";

import classes from "./LinearLoader.module.scss";

function LinearLoader({ loading = true }: { loading?: boolean }) {
  return (
    <div className={classes["loader"]}>
      {loading && (
        <LinearProgress
          classes={{
            root: classes["linear-progress"],
            bar: classes["linear-progress-bar"]
          }}
        />
      )}
    </div>
  );
}

export default LinearLoader;
