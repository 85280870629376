import { ReactNode } from "react";
import { useLocation } from "react-router";
import Title from "components/title/Title";
import TopBar from "./components/top-bar/TopBar";
import Footer from "./components/footer/Footer";
import classes from "./Layout.module.scss";

function Layout({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const viewBar = [
    "/",
    "/home",
    "/login",
    "/forgot-password",
    "/reset-password",
    "/user-not-found"
  ].includes(pathname);

  return (
    <>
      <Title />
      {!viewBar && <TopBar />}
      <div className={classes["content"]}>{children}</div>
      <Footer />
    </>
  );
}

export default Layout;
