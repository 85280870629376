import Login from "./components/login/Login";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ResetPassword from "./components/reset-password/ResetPassword";
import UserNotFound from "./components/user-not-found/UserNotFound";
import HomePageWrapper from "./components/home-page-wrapper/HomePageWrapper";
import LoginMethodNotAllowed from "./components/login-method-not-allowed/LoginMethodNotAllowed";

export const WrappedLoginForm = () => {
  return (
    <HomePageWrapper>
      <Login />
    </HomePageWrapper>
  );
};

export const WrappedForgotPasswordForm = () => (
  <HomePageWrapper>
    <ForgotPassword />
  </HomePageWrapper>
);

export const WrappedResetPasswordForm = () => (
  <HomePageWrapper>
    <ResetPassword />
  </HomePageWrapper>
);

export const WrappedUserNotFoundPage = () => (
  <HomePageWrapper>
    <UserNotFound />
  </HomePageWrapper>
);

export const WrappedLoginMethodNotAllowedPage = () => (
  <HomePageWrapper>
    <LoginMethodNotAllowed />
  </HomePageWrapper>
);
