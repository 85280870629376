import { createContext, ReactNode, useState } from "react";

const LoadingContext = createContext({
  loading: false,
  setLoading: (loading: boolean) => {}
});

export function LoadingContextProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export default LoadingContext;
