import { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

import { locales, setLanguageInCookie } from "utils/utils";

import Flag from "components/ui/flag/Flag";

import classes from "./LanguageSelector.module.scss";

type LocalesItemType = {
  code: string;
  translation: string;
};

function LanguageSelector() {
  const { i18n } = useTranslation("common", { useSuspense: false });
  const getDefaultLanguage = () => localStorage.getItem("i18nextLng");
  const [language, setLanguage] = useState(getDefaultLanguage());

  const handleLanguageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lng = e.target.value;
    i18n.changeLanguage(lng);
    setLanguage(lng);
    setLanguageInCookie(lng);
  };

  const getLabel = ({ code, translation }: LocalesItemType) => {
    return (
      <div className={classes.item}>
        <div className={classes["item-flag"]}>
          <Flag code={code} width="50px" height="25px" />
        </div>
        <div className={classes["item-label"]}>{translation}</div>
      </div>
    );
  };

  return (
    <FormControl
      component="fieldset"
      classes={{ root: classes["form-control"] }}
    >
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={language}
        onChange={handleLanguageChange}
      >
        {locales.map(item => (
          <FormControlLabel
            key={item.code}
            value={item.code}
            control={
              <Radio
                disableRipple
                icon={<RadioButtonUncheckedIcon className={classes["icon"]} />}
                checkedIcon={
                  <RadioButtonCheckedIcon className={classes["checked-icon"]} />
                }
              />
            }
            label={getLabel(item)}
            labelPlacement="start"
            classes={{ root: classes["form-control-label"] }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default LanguageSelector;
