import React, { useState } from "react";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { locales, setLanguageInCookie } from "utils/utils";

import Flag from "../../../ui/flag/Flag";

import classes from "./LanguageSelect.module.scss";

function LanguageSelect() {
  const { i18n } = useTranslation("common", { useSuspense: false });
  const getDefaultLanguage = () =>
    localStorage.getItem("i18nextLng") || i18n.language;
  const [language, setLanguage] = useState(getDefaultLanguage());

  const handleLanguageChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const lng = e.target.value as string;
    i18n.changeLanguage(lng);
    setLanguage(lng);
    setLanguageInCookie(lng);
  };

  const renderFlags = () =>
    locales.map(({ code, translation }) => (
      <MenuItem value={code} classes={{ root: classes.item }} key={code}>
        <div className={classes["item-flag"]}>
          <Flag code={code} />
        </div>
        <div className={classes["item-label"]}>{translation}</div>
      </MenuItem>
    ));

  return (
    <FormControl variant="outlined">
      <Select
        value={language}
        onChange={handleLanguageChange}
        inputProps={{
          name: "language",
          id: "language-select"
        }}
        className={classes.select}
      >
        {renderFlags()}
      </Select>
    </FormControl>
  );
}

export default LanguageSelect;
