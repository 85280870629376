import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";

import AuthContext from "contexts/AuthContext";
import { clearLocalStorage } from "utils/utils";

import Modal from "components/ui/modal/Modal";
import ApplyButton from "components/buttons/apply-button/ApplyButton";

import classes from "./ReAuthModal.module.scss";

function ReAuthModal() {
  const { shouldReAuth, setAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation("common");
  const { push } = useHistory();
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    clearLocalStorage();
    setAuthenticated(false);
    closeModal();
    push("/login");
  };

  useEffect(() => {
    if (shouldReAuth) {
      openModal();
    }
  }, [shouldReAuth]);

  return (
    <Modal open={open} onClose={closeModal}>
      <Modal.Header>
        <div className={classes["header"]}>
          <AccessTimeRoundedIcon className={classes["header-icon"]} />
          <span className={classes["header-title"]}>
            {t("re-authenticate-modal.header")}
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className={classes["content"]}>
          {t("re-authenticate-modal.content")}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <ApplyButton label="OK" onClick={handleLogOut} />
      </Modal.Footer>
    </Modal>
  );
}

export default ReAuthModal;
