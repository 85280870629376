import { UserProfile, UserFields } from "types/types";

export const updateUserInLocalStorage = ({
  firstName,
  lastName,
  phone
}: UserFields) => {
  const userString = localStorage.getItem("userDetails") as string;
  const user = JSON.parse(userString) as UserProfile;
  const updatedUser = { ...user, firstName, lastName, phone };

  localStorage.setItem("userDetails", JSON.stringify(updatedUser));
};
