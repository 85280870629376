import { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";

import { UserProfile } from "types/types";
import AuthContext from "contexts/AuthContext";
import { getUserInfo } from "api/auth";
import { getCurrentPath } from "utils/utils";

function useUserinfo() {
  const {
    authenticated,
    setAuthenticated,
    setUserDetails,
    shouldReAuth,
    setShouldReAuth
  } = useContext(AuthContext);
  const { push } = useHistory();
  const authenticatedRef = useRef(authenticated);
  const currentPath = getCurrentPath();

  const handleSuccess = (data: UserProfile) => {
    localStorage.setItem("userDetails", JSON.stringify(data));
    setUserDetails(data);
    setAuthenticated(true);
    if (shouldReAuth) {
      setShouldReAuth(false);
    }
  };

  useEffect(() => {
    if (authenticated && authenticatedRef.current !== authenticated) {
      if (currentPath) {
        push(currentPath);
      } else {
        push("/applications");
      }
    }
    authenticatedRef.current = authenticated;
  }, [authenticated, push, currentPath]);

  const { status, refetch: refetchUserData } = useQuery(
    "userInfo",
    getUserInfo,
    {
      onSuccess: handleSuccess,
      retry: false
    }
  );

  return { status, refetchUserData };
}

export default useUserinfo;
