import { instance } from "axios-instance/axios-instance";
import {
  ForgotPasswordResponse,
  LoginFormPayload,
  LoginResponse,
  ResetPasswordPayload,
  UserProfile,
} from "types/types";

export const login = async (
  loginForm: LoginFormPayload
): Promise<LoginResponse> => {
  const res = await instance.post<LoginResponse>("/auth", loginForm);
  return res.data as unknown as LoginResponse;
};

export const logout = async () => {
  const res = await instance.delete("/auth");
  return res.data;
};

export const resetPassword = async (
  payload: ResetPasswordPayload
): Promise<string> => {
  const res = await instance.post<string>("/auth/password/reset", payload);
  return res.data;
};

export const forgotPassword = async (email: string) => {
  const res = await instance.post<ForgotPasswordResponse>(
    "/auth/password/forgot",
    { email }
  );
  return res.data;
};

export const getUserInfo = async (): Promise<UserProfile> => {
  const res = await instance.get<UserProfile>("/auth/me");
  return res.data;
};
