import { useContext, useEffect, useState } from "react";

import AuthContext from "contexts/AuthContext";

function usePermissions() {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    if (userDetails.permissions) {
      setPermissions(userDetails.permissions);
    }
  }, [userDetails]);

  return permissions;
}

export default usePermissions;
