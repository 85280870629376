import {
  useContext,
  useState,
  SyntheticEvent,
  KeyboardEvent,
  useRef,
  useEffect
} from "react";
import { Button } from "@material-ui/core";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import SwipeableViews from "react-swipeable-views";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useTranslation } from "react-i18next";
import { TiVendorMicrosoft } from "react-icons/ti";

import { LoginFormPayload, LoginResponse } from "types/types";
import NotificationContext from "contexts/NotificationContext";
import { login } from "api/auth";
import {
  getInputError,
  getErrorMessage,
  validateRequired,
  validateEmail,
  validatePassword,
  MAX_EMAIL_LEN,
  MAX_PASSWORD_LEN,
  MIN_PASSWORD_LEN,
  openURL
} from "utils/utils";
import useUserInfo from "hooks/useUserInfo";

import LoginHeader from "../login-header/LoginHeader";
import Form from "components/ui/form/Form";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import CircleLoader from "components/ui/loaders/circle-loader/CircleLoader";

import classes from "./Login.module.scss";

type FormData = {
  email: string;
  password: string;
};

function Login() {
  const [tabIndex, setTabIndex] = useState(0);
  const { setSnackbarOptions } = useContext(NotificationContext);
  const { t } = useTranslation("common");
  const {
    register,
    triggerValidation,
    errors,
    clearError,
    formState: { isValid },
    getValues
  } = useForm<FormData>({
    mode: "onChange"
  });
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const passwordInputRef = useRef<HTMLInputElement | null>(null);

  const { refetchUserData } = useUserInfo();

  const handleLoginSuccess = async () => {
    setSnackbarOptions({
      open: true,
      message: t("login-logout.login-success"),
      type: "success"
    });

    await refetchUserData();
  };

  const handleLoginError = (error: Error) => {
    const message = getErrorMessage(error);

    setSnackbarOptions({
      open: true,
      message,
      type: "error"
    });
    setTabIndex(0);
    emailInputRef.current?.focus();
  };

  const { mutate: loginAction, isLoading } = useMutation<
    LoginResponse,
    Error,
    LoginFormPayload
  >(login, {
    onSuccess: handleLoginSuccess,
    onError: handleLoginError
  });

  const onSubmit = () => {
    const email = getValues().email;
    const password = getValues().password;

    loginAction({ email, password });
  };

  const handleNextButton = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTabIndex(1);
    clearError();
  };

  const handleBackArrowClick = () => {
    clearError("password");
    setTabIndex(0);
  };

  const handleKeyDown = async (e: KeyboardEvent) => {
    if (e.key === "Tab" || e.key === "Enter") {
      const valid = await triggerValidation("email");
      if (valid) {
        setTabIndex(1);
      }
    }
  };

  const handlePressEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      if (isValid) {
        onSubmit();
      }
    }
  };

  const handleSSOClick = () => {
    openURL("/api/v1/auth/azure/", false);
  };

  useEffect(() => {
    if (tabIndex === 1) {
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  }, [tabIndex]);

  return (
    <div className={classes.container}>
      <div className={classes["login-panel"]}>
        <LoginHeader />
        <div className={classes["form-container"]}>
          <Form>
            <SwipeableViews index={tabIndex}>
              <Form.Field>
                <Form.Input
                  autoFocus
                  name="email"
                  placeholder={t("email-placeholder")}
                  ref={e => {
                    register(e, {
                      ...validateRequired,
                      ...validateEmail
                    });
                    emailInputRef.current = e;
                  }}
                  onKeyDown={handleKeyDown}
                  error={getInputError(errors.email, t, MAX_EMAIL_LEN)}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="password"
                  placeholder={t("password-placeholder")}
                  type="password"
                  ref={e => {
                    register(e, {
                      ...validateRequired,
                      ...validatePassword
                    });
                    passwordInputRef.current = e;
                  }}
                  onKeyDown={handlePressEnter}
                  error={getInputError(
                    errors.password,
                    t,
                    MAX_PASSWORD_LEN,
                    MIN_PASSWORD_LEN
                  )}
                />
              </Form.Field>
            </SwipeableViews>

            <div className={classes["bottom-container"]}>
              {tabIndex === 1 && (
                <div
                  className={classes["back-icon-container"]}
                  onClick={handleBackArrowClick}
                >
                  <KeyboardBackspaceIcon className={classes["back-icon"]} />
                  <span className={classes["back-icon-label"]}>
                    {t("back")}
                  </span>
                </div>
              )}
              <div className={classes["helper"]} tabIndex={-1}>
                <Link to="/forgot-password" className={classes["helper-text"]}>
                  {t("forgot-password")}
                </Link>
              </div>
              <div className={classes["right-container"]}>
                {tabIndex === 0 ? (
                  <PrimaryButton
                    disabled={!!errors.email}
                    onClick={handleNextButton}
                  >
                    {t("next")}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    disabled={!!errors.password || !isValid || isLoading}
                    onClick={onSubmit}
                  >
                    {!isLoading ? (
                      <span>{t("sign-in")}</span>
                    ) : (
                      <CircleLoader />
                    )}
                  </PrimaryButton>
                )}
              </div>
            </div>

            <div className={classes["sso-container"]}>
              <div className={classes["sso-header"]}>
                <div className={classes["line"]} />
                <div className={classes["or"]}>{t("or")}</div>
                <div className={classes["line"]} />
              </div>

              <Button
                variant="contained"
                classes={{ root: classes["sso-button"] }}
                onClick={handleSSOClick}
              >
                <div className={classes["sso-button-label"]}>
                  <TiVendorMicrosoft className={classes["sso-icon"]} />
                  <span>{t("sso-login-microsoft")}</span>
                </div>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
