export type AppObject = { id: number; name: string };

export type UserProfile = {
  id: string;
  displayName: string;
  email: string;
  firstName: string;
  lastName: string;
  permissions: string[];
  phone: string;
  role: string;
};

export type LoginFormPayload = {
  email: string;
  password: string;
};

export type LoginResponse = {
  user: UserProfile;
  token: string;
};

export type ResetPasswordPayload = {
  token: string;
  password: string;
};

export type UserFields = {
  firstName: string;
  lastName: string;
  displayName: string;
  phone: string;
};

export type EditUserProfilePayload = {
  firstName: string;
  lastName: string;
  phone: string;
};

export type EditUserProfileResponse = {
  Company: { id: number; name: string } | null;
  companyId: number | null;
  createdAt: string;
  email: string;
  firstName: string;
  guid: string;
  id: number;
  lastName: string;
  lastSeenAt: string | null;
  password: string;
  passwordResetToken: string;
  passwordResetTokenExpiresAt: string;
  phone: string;
  roleId: number;
  updatedAt: string;
};

export type ForgotPasswordResponse = {
  success: boolean;
  message: string;
};

export type Credentials = {
  user: UserProfile;
  token: string;
  relogged: boolean;
  delay: number;
};

export type Application = {
  id: number;
  guid: string;
  name: string;
  authUrl: string;
  companyPropagationUrl: string;
  userPropagationUrl: string;
  description: string;
  descriptionHeader: string;
  logoFile: string;
  order: number;
  apiKey: string;
  createdAt: string;
  updatedAt: string;
};

export type LoginMethod = {
    id: number;
    name: string;
}

export type SelectApplicationResponse = {
  success: boolean;
  redirect_uri: string;
};

export type Company = {
  id: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  country: string;
  createdAt: string;
  updatedAt: string;
  guid: string;
};

export type CreateCompanyFormData = {
  name: string;
  email: string;
  phone: string;
  address: string;
  country: string;
};

export type EditCompanyFormData = {
  name: string;
  email: string;
  phone: string;
  address: string;
  country: string;
};

export type EditCompanyPayload = {
  id: number;
  name: string;
  email: string;
  phone: string;
  country: string;
  address: string;
};

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  loginMethod: LoginMethod;
  UsersApplications: AppObject[];
  Company: {
    id: string;
    name: string;
  } | null;
  Role: {
    id: number;
    name: string;
  };
};

export type CreateUserFormData = {
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  roleId: number;
  applications: number[];
  phone: string;
  loginMethod: string;
};

export type CreateUserPayload = {
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  roleId: number;
  applications: number[];
  phone: string;
  loginMethod: string;
  sendEmail: boolean;
};

export type EditSelectedUserFormData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  roleName: string;
  loginMethod: string;
};

export type EditUserPayload = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  roleId: number;
  applications: number[];
  phone: string;
  loginMethod: string;
};

export type Role = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type SnackbarOptions = {
  message: string;
  open: boolean;
  type: "error" | "info" | "success" | "warning";
};

export enum Permission {
  UserGet = "USER_GET",
  UserUpdate = "USER_UPDATE",
  UserCreate = "USER_CREATE",
  UserDelete = "USER_DELETE",
  ApplicationGet = "APPLICATION_GET",
  ApplicationGetSelf = "APPLICATION_GET_SELF",
  ApplicationUpdate = "APPLICATION_UPDATE",
  ApplicationCreate = "APPLICATION_CREATE",
  ApplicationDelete = "APPLICATION_DELETE",
  CompanyGet = "COMPANY_GET",
  CompanyUpdate = "COMPANY_UPDATE",
  CompanyCreate = "COMPANY_CREATE",
  CompanyDelete = "COMPANY_DELETE",
  RoleGet = "ROLE_GET",
  RoleUpdate = "ROLE_UPDATE",
  RoleCreate = "ROLE_CREATE",
  RoleDelete = "ROLE_DELETE",
  PermissionGet = "PERMISSION_GET",
  PermissionUpdate = "PERMISSION_UPDATE",
  PermissionCreate = "PERMISSION_CREATE",
  PermissionDelete = "PERMISSION_DELETE",
  UserCreateContentEditor = "USER_CREATE_CONTENT_EDITOR",
  UserCreateContentCustomer = "USER_CREATE_CUSTOMER",
  UserCreateContentSiteManager = "USER_CREATE_SITE_MANAGER",
  UserCreateSuperAdmin = "USER_CREATE_SUPER_ADMIN"
}

type Position = "absolute" | "relative" | undefined;

export type TrackVerticalStyle = {
  style: {
    opacity: number;
    position: Position;
    transition: string;
    width: string | number;
  };
};

export type ThumbVerticalStyle = {
  style: {
    display: string;
    position: Position;
    width: string | number;
  };
};
