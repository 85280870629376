import React, {
  ChangeEvent,
  KeyboardEventHandler,
  FocusEventHandler
} from "react";
import clsx from "clsx";

import classes from "./FormInput.module.scss";

type FormInputProps = {
  error?: string | undefined;
  formInputClass?: string;
  autoFocus?: boolean;
  name: string;
  placeholder?: string;
  type?: string;
  defaultValue?: string;
  readOnly?: boolean;
  onKeyDown?: KeyboardEventHandler;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler;
};

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      error = "",
      formInputClass = "",
      autoFocus = false,
      name,
      placeholder = "",
      type = "text",
      defaultValue = "",
      readOnly = false,
      onKeyDown,
      value,
      onChange,
      onFocus
    },
    ref
  ) => {
    return (
      <div
        className={clsx(classes.container, {
          [formInputClass]: formInputClass
        })}
      >
        <input
          ref={ref}
          type={type}
          name={name}
          autoFocus={autoFocus}
          className={clsx(classes.input, {
            [classes["error-border"]]: error
          })}
          autoComplete="off"
          placeholder={placeholder}
          defaultValue={defaultValue}
          readOnly={readOnly}
          onKeyDown={onKeyDown}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
        />

        <span className={classes["error-mssg"]}>{error}</span>
      </div>
    );
  }
);

export default FormInput;
