import axios from "axios";

import { BASE_URL } from "utils/utils";

export const instance = axios.create({
  baseURL: BASE_URL + "/api/v1",
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});
