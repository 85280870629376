import { instance } from "axios-instance/axios-instance";
import { EditUserProfilePayload, EditUserProfileResponse } from "types/types";

export const editUser = async (payload: EditUserProfilePayload) => {
  const res = await instance.patch<EditUserProfileResponse>(
    "/user/edit",
    payload
  );
  return res.data;
};
