import { useTranslation } from "react-i18next";

import Modal from "components/ui/modal/Modal";
import PersonalInfoForm from "components/forms/personal-info-form/PersonalInfoForm";

type PersonalInfoModalType = {
  open: boolean;
  toggleModalOpen: () => void;
};

function PersonalInfoModal({ open, toggleModalOpen }: PersonalInfoModalType) {
  const { t } = useTranslation("edit-profile", { useSuspense: false });

  const handleClose = () => {
    toggleModalOpen();
  };

  return (
    <Modal open={open} onClose={toggleModalOpen}>
      <Modal.Header onClose={handleClose}>
        {t("personal-information")}
      </Modal.Header>
      <Modal.Content>
        <PersonalInfoForm toggleModalOpen={toggleModalOpen} />
      </Modal.Content>
    </Modal>
  );
}

export default PersonalInfoModal;
