import { useLocation } from "react-router-dom";

import auraLogo from "assets/images/AURA_MARK_LOGO_WHITE_Small.png";

import classes from "./AuraLogo.module.scss";

type AuraLogoProps = {
  handleApplicationsClick?: () => void;
};

function AuraLogo({ handleApplicationsClick }: AuraLogoProps) {
  const { pathname } = useLocation();

  const showPointer =
    !!handleApplicationsClick && !pathname.includes("/applications");

  return (
    <div
      className={showPointer ? classes.pointer : ""}
      onClick={handleApplicationsClick ? handleApplicationsClick : () => {}}
    >
      <img src={auraLogo} className={classes["image"]} alt="logo" />
    </div>
  );
}

export default AuraLogo;
