import { ReactNode } from "react";
import { DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import classes from "../../Modal.module.scss";

type ModalHeaderType = {
  children?: ReactNode;
  description?: ReactNode;
  onClose?: () => void;
  noPadding?: boolean;
};

function ModalHeader({
  children = null,
  description = "",
  onClose,
  noPadding
}: ModalHeaderType) {
  return (
    <DialogTitle
      disableTypography
      className={classes["modal-header"]}
      classes={{ root: noPadding ? classes["no-padding"] : "" }}
    >
      <div className={classes["modal-header-top-bar"]}>
        <div className={classes["children-container"]}>
          <div>{children}</div>
        </div>
        {onClose && (
          <CloseIcon className={classes["close-icon"]} onClick={onClose} />
        )}
      </div>

      {description && (
        <div className={classes["modal-header-description"]}>{description}</div>
      )}
    </DialogTitle>
  );
}

export default ModalHeader;
