import idxLogo from "assets/images/idxWhiteTitle_Medium.png";

import classes from "./IDXLogo.module.scss";

function IdxLogo() {
  return (
    <div className={classes.container}>
      <img src={idxLogo} alt="Idx Logo" className={classes.image} />
    </div>
  );
}

export default IdxLogo;
