import { ReactNode, KeyboardEvent } from "react";
import { Dialog } from "@material-ui/core";
import clsx from "clsx";

import ModalHeader from "./components/modal-header/ModalHeader";
import ModalContent from "./components/modal-content/ModalContent";
import ModalFooter from "./components/modal-footer/ModalFooter";

import classes from "./Modal.module.scss";

type ModalType = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  modalClass?: string;
};

function Modal({ open, onClose, children, modalClass = "" }: ModalType) {
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Escape") {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: clsx(classes["modal"], { [modalClass]: modalClass }) }}
      onKeyDown={onKeyDown}
    >
      {children}
    </Dialog>
  );
}

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
