import { ReactNode, KeyboardEventHandler } from "react";

import FormField from "./form-field/FormField";
import FormInput from "./form-input/FormInput";
import FormLabel from "./form-label/FormLabel";
import FormTextarea from "./form-textarea/FormTextarea";

import classes from "./Form.module.scss";

type FormType = {
  children: ReactNode;
  onKeyDown?: KeyboardEventHandler;
};

function Form({ children, onKeyDown = undefined }: FormType) {
  return (
    <form className={classes["form"]} onKeyDown={onKeyDown} autoComplete="off">
      {children}
    </form>
  );
}

Form.Field = FormField;
Form.Label = FormLabel;
Form.Input = FormInput;
Form.Textarea = FormTextarea;

export default Form;
