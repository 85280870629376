import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useUserInfo from "hooks/useUserInfo";
import { getCurrentPath, clearLocalStorage } from "utils/utils";

import HomePageWrapper from "pages/home/components/home-page-wrapper/HomePageWrapper";
import idxLogo from "assets/images/idxWhiteTitleAlt_Large.png";

import classes from "./LandingPage.module.scss";

function LandingPage() {
  const { status } = useUserInfo();
  const { push } = useHistory();
  const currentPath = getCurrentPath();

  useEffect(() => {
    if (status === "error") {
      push("/");
      clearLocalStorage();
    }

    if (status === "success") {
      if (currentPath) {
        push(currentPath);
      }
    }
  }, [status, push, currentPath]);

  return (
    <HomePageWrapper>
      <div className={classes.container}>
        {!currentPath && (
          <div className={classes["logo-container"]}>
            <img src={idxLogo} alt="IDX Logo" className={classes.logo} />
          </div>
        )}
      </div>
    </HomePageWrapper>
  );
}

export default LandingPage;
