import { useContext } from "react";
import { Redirect } from "react-router-dom";

import AuthContext from "contexts/AuthContext";

function RedirectRoute() {
  const { authenticated } = useContext(AuthContext);

  if (!authenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return null;
}

export default RedirectRoute;
