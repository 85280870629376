import { ReactNode } from "react";
import { DialogActions } from "@material-ui/core";

import classes from "../../Modal.module.scss";

function ModalFooter({ children }: { children: ReactNode }) {
  return (
    <DialogActions className={classes["modal-footer"]}>
      {children}
    </DialogActions>
  );
}

export default ModalFooter;
