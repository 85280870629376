import { ClickAwayListener, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LockIcon from "@material-ui/icons/Lock";
import TranslateIcon from "@material-ui/icons/Translate";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InfoIcon from "@material-ui/icons/Info";
import { RiBubbleChartLine } from "react-icons/ri";
import { IoMdApps } from "react-icons/io";

import usePermissions from "hooks/usePermissions";
import { Permission } from "types/types";

import classes from "./EditProfileMenu.module.scss";

type EditProfileMenuProps = {
  open: boolean;
  onClose: () => void;
  togglePersonalInfoModal: () => void;
  openResetPasswordModal: () => void;
  openLanguageModal: () => void;
  openLogoutModal: () => void;
  openAboutModal: () => void;
  handleAccessClick: () => void;
  handleApplicationsClick: () => void;
};

const Menu = "ul";
const Option = "li";

function EditProfileMenu({
  open,
  onClose,
  togglePersonalInfoModal,
  openResetPasswordModal,
  openLanguageModal,
  openLogoutModal,
  openAboutModal,
  handleAccessClick,
  handleApplicationsClick
}: EditProfileMenuProps) {
  const { t } = useTranslation("edit-profile", { useSuspense: false });
  const userPermissions = usePermissions();

  const hasAccessToUsersAndCompanies =
    userPermissions.includes(Permission.UserGet) &&
    userPermissions.includes(Permission.CompanyGet);

  let options = [
    {
      id: "profile",
      name: t("menu.edit-profile"),
      icon: <AccountBoxIcon />,
      cb: togglePersonalInfoModal
    },
    {
      id: "password",
      name: t("menu.password-reset"),
      icon: <LockIcon />,
      cb: openResetPasswordModal
    },
    {
      id: "language",
      name: t("menu.language"),
      icon: <TranslateIcon />,
      cb: openLanguageModal
    },
    {
      id: "applications",
      name: t("menu.apps"),
      icon: <IoMdApps className={classes["apps-icon"]} />,
      cb: handleApplicationsClick
    },
    {
      id: "access",
      name: t("access:header"),
      icon: <RiBubbleChartLine />,
      cb: handleAccessClick
    },
    {
      id: "logout",
      name: t("menu.log-out"),
      icon: <ExitToAppIcon />,
      cb: openLogoutModal
    },
    {
      id: "about",
      name: t("menu.about"),
      icon: <InfoIcon />,
      cb: openAboutModal
    }
  ];

  if (!hasAccessToUsersAndCompanies) {
    options = options
      .filter(({ id }) => id !== "access")
      .filter(({ id }) => id !== "applications");
  }

  const handleOnclick = (cb: () => void) => {
    cb();
    onClose();
  };

  return open ? (
    <ClickAwayListener onClickAway={onClose}>
      <Paper classes={{ root: classes["side-menu"] }}>
        <Menu className={classes["options"]}>
          {options.map(({ id, name, icon, cb }) => (
            <Option
              key={id}
              className={classes["options-item"]}
              onClick={() => handleOnclick(cb)}
            >
              <span>{icon}</span>
              <span>{name}</span>
            </Option>
          ))}
        </Menu>
      </Paper>
    </ClickAwayListener>
  ) : null;
}

export default EditProfileMenu;
