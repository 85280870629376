import { useContext, useState } from "react";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-query";

import AuthContext from "contexts/AuthContext";
import { clearLocalStorage } from "utils/utils";
import NotificationContext from "contexts/NotificationContext";
import { forgotPassword, logout } from "api/auth";
import { ForgotPasswordResponse } from "types/types";

import EditProfileMenu from "./components/edit-profile-menu/EditProfileMenu";
import PersonalInfoModal from "components/modals/personal-info-modal/PersonalInfoModal";
import ConfirmationModal from "components/modals/confirmation-modal/ConfirmationModal";
import LanguageModal from "components/modals/language-modal/LanguageModal";
import AboutModal from "components/about-modal/AboutModal";

import classes from "./User.module.scss";

function User() {
  const [editProfileMenuOpen, setEditProfileMenuOpen] = useState(false);
  const [persnalInfoModalOpen, setPersnalInfoModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const { setSnackbarOptions } = useContext(NotificationContext);
  const { userDetails, setAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation("edit-profile", { useSuspense: false });
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleLogoutSuccess = () => {
    setSnackbarOptions({
      open: true,
      message: t("common:login-logout.logout-success"),
      type: "success"
    });
    clearLocalStorage();
    closeLogoutModal();
    setAuthenticated(false);
    push("/");
  };

  const handleLogoutError = () => {
    setSnackbarOptions({
      open: true,
      message: t("common:login-logout.logout-error"),
      type: "error"
    });
  };

  const { mutate: logoutAction } = useMutation<Error>(logout, {
    onSuccess: handleLogoutSuccess,
    onError: handleLogoutError
  });

  const handleLogout = () => {
    logoutAction();
  };

  const onResetPasswordSuccess = () => {
    closeResetPasswordModal();
    setSnackbarOptions({
      open: true,
      message: t("snacks.email-with-reset-password-link-has-been-sent"),
      type: "success"
    });
  };

  const onResetPasswordError = () => {
    setSnackbarOptions({
      open: true,
      message: t("snacks.password-could-not-be-reset"),
      type: "error"
    });
  };

  const { mutate: resetPasswordAction } = useMutation<
    ForgotPasswordResponse,
    Error,
    string
  >(forgotPassword, {
    onSuccess: onResetPasswordSuccess,
    onError: onResetPasswordError
  });

  const handleResetPassword = () => {
    const { email } = userDetails;

    resetPasswordAction(email);
  };

  const getUserRoleName = (role: string) => {
    const key = role.split(" ").join("-").toLocaleLowerCase();
    return t(`common:roles.${key}`);
  };

  const openEditProfileMenu = () => {
    setEditProfileMenuOpen(true);
  };

  const closeEditProfileMenu = () => {
    setEditProfileMenuOpen(false);
  };

  const togglePersonalInfoModal = () => {
    setPersnalInfoModalOpen(prev => !prev);
  };

  const openResetPasswordModal = () => {
    setResetPasswordModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  const openLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const openAboutModal = () => {
    setAboutModalOpen(true);
  };

  const closeAboutModal = () => {
    setAboutModalOpen(false);
  };

  const handleAccessClick = () => {
    if (!pathname.includes("access")) {
      push("/access");
    }
  };

  const handleApplicationsClick = () => {
    if (!pathname.includes("applications")) {
      push("/applications");
    }
  };

  return (
    <>
      <div className={classes["user"]}>
        <div
          className={classes["user-info-container"]}
          onClick={openEditProfileMenu}
        >
          <div className={classes["user-info"]}>
            <span className={classes["user-info-name"]}>
              {userDetails.displayName}
            </span>
            <span className={classes["user-info-role"]}>
              &#40;{getUserRoleName(userDetails.role)}&#41;
            </span>
            <PersonSharpIcon classes={{ root: classes["user-info-icon"] }} />
          </div>
        </div>
      </div>

      <EditProfileMenu
        open={editProfileMenuOpen}
        onClose={closeEditProfileMenu}
        togglePersonalInfoModal={togglePersonalInfoModal}
        openResetPasswordModal={openResetPasswordModal}
        openLanguageModal={openLanguageModal}
        openLogoutModal={openLogoutModal}
        openAboutModal={openAboutModal}
        handleAccessClick={handleAccessClick}
        handleApplicationsClick={handleApplicationsClick}
      />

      <PersonalInfoModal
        open={persnalInfoModalOpen}
        toggleModalOpen={togglePersonalInfoModal}
      />

      <ConfirmationModal
        open={resetPasswordModalOpen}
        onClose={closeResetPasswordModal}
        onAction={handleResetPassword}
        actionName={t("reset")}
        message={t("are-you-sure-you-want-to-reset-password")}
      />

      <LanguageModal open={languageModalOpen} onClose={closeLanguageModal} />

      <ConfirmationModal
        open={logoutModalOpen}
        onClose={closeLogoutModal}
        onAction={handleLogout}
        actionName={t("menu.log-out")}
        message={t("logout-confirmation")}
      />

      <AboutModal open={aboutModalOpen} onClose={closeAboutModal} />
    </>
  );
}

export default User;
