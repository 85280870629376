import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { EditUserProfilePayload, EditUserProfileResponse } from "types/types";
import { editUser } from "api/userProfile";
import NotificationContext from "contexts/NotificationContext";
import { updateUserInLocalStorage } from "./utils/utils";

import AuthContext from "contexts/AuthContext";
import {
  getInputError,
  validateRequired,
  validateName,
  validatePhone,
  MAX_DEFAULT_LEN,
  MAX_PHONE_LEN
} from "utils/utils";

import Form from "components/ui/form/Form";
import ApplyButton from "components/buttons/apply-button/ApplyButton";

import classes from "./PersonalInfoForm.module.scss";

type PersonalInfoFormProps = {
  toggleModalOpen: () => void;
};

type FormData = {
  firstName: string;
  lastName: string;
  phone: string;
};

function PersonalInfoForm({ toggleModalOpen }: PersonalInfoFormProps) {
  const { userDetails, setUserDetails } = useContext(AuthContext);
  const { setSnackbarOptions } = useContext(NotificationContext);
  const { t } = useTranslation("edit-profile", { useSuspense: true });
  const {
    register,
    errors,
    getValues,
    formState: { dirty, isValid }
  } = useForm<FormData>({
    defaultValues: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      phone: userDetails.phone || ""
    },
    mode: "onChange"
  });
  const disabled = !dirty || !isValid;

  const handleEditUserSuccess = (response: EditUserProfileResponse) => {
    const { firstName, lastName, phone } = response;
    const displayName = `${firstName} ${lastName}`;

    setSnackbarOptions({
      open: true,
      message: t("snacks.profile-has-been-edited"),
      type: "success"
    });

    updateUserInLocalStorage({ firstName, lastName, displayName, phone });
    setUserDetails({
      ...userDetails,
      firstName,
      lastName,
      displayName,
      phone
    });
    toggleModalOpen();
  };

  const handleEditUserError = () => {
    setSnackbarOptions({
      open: true,
      message: "Unable to edit user details",
      type: "error"
    });
  };

  const { mutate: editUserProfile, isLoading } = useMutation<
    EditUserProfileResponse,
    Error,
    EditUserProfilePayload
  >(editUser, {
    onSuccess: handleEditUserSuccess,
    onError: handleEditUserError
  });

  const onSubmit = () => {
    const { firstName, lastName, phone } = getValues();

    const payload = {
      firstName,
      lastName,
      phone
    };

    editUserProfile(payload);
  };

  return (
    <div className={classes["form-container"]}>
      <Form>
        <Form.Field horizontal>
          <Form.Label>{t("form.email")}</Form.Label>
          <Form.Input name="email" readOnly defaultValue={userDetails.email} />
        </Form.Field>

        <Form.Field horizontal>
          <Form.Label>{t("form.first-name")}</Form.Label>
          <Form.Input
            name="firstName"
            ref={register({
              ...validateRequired,
              maxLength: {
                value: MAX_DEFAULT_LEN,
                message: "common:form.max-length"
              },
              ...validateName
            })}
            error={getInputError(errors.firstName, t, MAX_DEFAULT_LEN)}
          />
        </Form.Field>

        <Form.Field horizontal>
          <Form.Label>{t("form.last-name")}</Form.Label>
          <Form.Input
            name="lastName"
            ref={register({
              ...validateRequired,
              maxLength: {
                value: MAX_DEFAULT_LEN,
                message: "common:form.max-length"
              },
              ...validateName
            })}
            error={getInputError(errors.lastName, t, MAX_DEFAULT_LEN)}
          />
        </Form.Field>

        <Form.Field horizontal>
          <Form.Label optional>{t("form.phone-number")}</Form.Label>
          <Form.Input
            name="phone"
            ref={register({
              ...validatePhone
            })}
            error={getInputError(errors.phone, t, MAX_PHONE_LEN)}
          />
        </Form.Field>
        <div className={classes["actions"]}>
          <ApplyButton
            onClick={onSubmit}
            disabled={disabled}
            loading={isLoading}
          />
        </div>
      </Form>
    </div>
  );
}

export default PersonalInfoForm;
