import { useContext, KeyboardEvent } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import NotificationContext from "contexts/NotificationContext";
import {
  getInputError,
  MAX_PASSWORD_LEN,
  MIN_PASSWORD_LEN,
  validatePassword,
  validateRequired
} from "utils/utils";
import { ResetPasswordPayload } from "types/types";
import { resetPassword } from "api/auth";

import LoginHeader from "../login-header/LoginHeader";
import Form from "components/ui/form/Form";
import ApplyButton from "components/buttons/apply-button/ApplyButton";

import classes from "../login/Login.module.scss";

type FormData = {
  password: string;
};

function ResetPassword() {
  const {
    register,
    errors,
    getValues,
    formState: { dirty, isValid }
  } = useForm<FormData>({ mode: "onChange" });
  const location = useLocation();
  const history = useHistory();
  const { setSnackbarOptions } = useContext(NotificationContext);
  const { t } = useTranslation("common");
  const disabled = !dirty || !isValid;

  const handleResetPasswordSuccess = () => {
    setSnackbarOptions({
      open: true,
      message: t("snacks.reset-password-success"),
      type: "success"
    });

    setTimeout(() => {
      history.push("/");
    }, 2000);
  };

  const handleResetPasswordError = () => {
    setSnackbarOptions({
      open: true,
      message: t("snacks.reset-password-error"),
      type: "error"
    });
  };

  const { mutateAsync: handleResetPassword, isLoading } = useMutation<
    string,
    Error,
    ResetPasswordPayload
  >(resetPassword, {
    onSuccess: handleResetPasswordSuccess,
    onError: handleResetPasswordError
  });

  const onSubmit = async () => {
    const newPassword = getValues().password;
    const token = location.search.replace("?", "").split("=")[1];

    if (token) {
      const payload = { password: newPassword, token };
      await handleResetPassword(payload);
    }
  };

  const handlePressEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isValid) {
        onSubmit();
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={clsx(classes["login-panel"], classes["small"])}>
        <LoginHeader resetPassword />
        <div className={classes["form-container"]}>
          <Form>
            <Form.Field>
              <Form.Input
                type="password"
                name="password"
                placeholder={t("password-placeholder")}
                ref={register({
                  ...validateRequired,
                  ...validatePassword
                })}
                onKeyDown={handlePressEnter}
                error={getInputError(
                  errors.password,
                  t,
                  MAX_PASSWORD_LEN,
                  MIN_PASSWORD_LEN
                )}
              />
            </Form.Field>

            <div className={classes["bottom-container"]}>
              <div className={classes["helper"]}></div>
              <div className={classes["right-container"]}>
                <ApplyButton
                  label={t("change-password")}
                  onClick={onSubmit}
                  disabled={disabled}
                  loading={isLoading}
                  smallFont
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
