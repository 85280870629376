import { useContext, KeyboardEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import { forgotPassword } from "api/auth";
import { ForgotPasswordResponse } from "types/types";

import {
  getInputError,
  validateRequired,
  validateEmail,
  MAX_EMAIL_LEN
} from "utils/utils";

import NotificationContext from "contexts/NotificationContext";

import LoginHeader from "../login-header/LoginHeader";
import Form from "components/ui/form/Form";
import ApplyButton from "components/buttons/apply-button/ApplyButton";

import classes from "../login/Login.module.scss";

type FormData = {
  email: string;
};

function ForgotPassword() {
  const {
    register,
    errors,
    getValues,
    formState: { dirty, isValid }
  } = useForm<FormData>({
    mode: "onChange"
  });
  const { setSnackbarOptions } = useContext(NotificationContext);
  const { push } = useHistory();
  const { t } = useTranslation("common");
  const disabled = !dirty || !isValid;

  const onForgotPasswordSuccess = () => {
    setSnackbarOptions({
      open: true,
      message: t("snacks.forgot-password-success"),
      type: "success"
    });

    setTimeout(() => {
      push("/");
    }, 2500);
  };

  const onForgotPasswordError = () => {
    setSnackbarOptions({
      open: true,
      message: t("snacks.forgot-password-error"),
      type: "error"
    });
  };

  const { mutate: forgotPasswordAction } = useMutation<
    ForgotPasswordResponse,
    Error,
    string
  >(forgotPassword, {
    onSuccess: onForgotPasswordSuccess,
    onError: onForgotPasswordError
  });

  const handleResetPassword = () => {
    const email = getValues().email;

    forgotPasswordAction(email);
  };

  const handlePressEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isValid) {
        handleResetPassword();
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes["login-panel"]}>
        <LoginHeader forgotPassword />
        <div className={classes["form-container"]}>
          <Form>
            <Form.Field>
              <Form.Input
                name="email"
                placeholder="Email"
                ref={register({
                  ...validateRequired,
                  ...validateEmail
                })}
                onKeyDown={handlePressEnter}
                error={getInputError(errors.email, t, MAX_EMAIL_LEN)}
              />
            </Form.Field>

            <div className={classes["bottom-container"]}>
              <div className={classes["helper"]}>
                <Link to="/login" className={classes["helper-text"]}>
                  <KeyboardBackspaceIcon className={classes["back-icon"]} />
                  <span>{t("back-to-sign-in-form")}</span>
                </Link>
              </div>
              <div className={classes["right-container"]}>
                <ApplyButton
                  label={t("forgot-password-form.reset-password-button")}
                  onClick={handleResetPassword}
                  disabled={disabled}
                  smallFont
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
