import { ReactNode } from "react";
import clsx from "clsx";

import Modal from "../../ui/modal/Modal";
import ApplyButton from "components/buttons/apply-button/ApplyButton";

import classes from "./ConfirmationModal.module.scss";

type ConfirmationModalType = {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
  actionName?: string;
  message?: string;
  headerContent?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  contentClass?: string;
  children?: ReactNode;
};

function ConfirmationModal({
  open,
  onClose,
  onAction,
  actionName = "Apply",
  message,
  headerContent = null,
  disabled = false,
  loading = false,
  contentClass = "",
  children
}: ConfirmationModalType) {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header onClose={onClose} noPadding>
        {headerContent && <div>{headerContent}</div>}
      </Modal.Header>
      <Modal.Content>
        <div
          className={clsx(classes["confirmation-modal-content"], {
            [contentClass]: contentClass
          })}
        >
          {message}
          {children}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <ApplyButton
          onClick={onAction}
          label={actionName}
          disabled={disabled}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
