import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function Title() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["access", "applications"], {
    useSuspense: false
  });

  let section = "";

  if (pathname.includes("users")) {
    section = `- ${t("users.header.users")}`;
  }

  if (pathname.includes("companies")) {
    section = `- ${t("companies.header.companies")}`;
  }

  if (pathname.includes("applications")) {
    section = `- ${t("applications:header")}`;
  }

  return (
    <Helmet>
      <title>IDX&nbsp;{section}</title>
    </Helmet>
  );
}

export default Title;
